import React from "react"

import AppContext from "../context"

const TopBar = ({})=>{

    const {auth, user} = React.useContext(AppContext)
    const [show, setShow] = React.useState(false)

    return <nav className="navbar navbar-dark bg-dark navbar-expand-md">
      <div className="container">
        <a className="navbar-brand" href="/">
        {
          // <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" className="d-inline-block align-top" alt=""/>

        }
          Smartguide Online
        </a>
        <button className="navbar-toggler" type="button" onClick={()=>{setShow(!show)}}>
          <span className="navbar-toggler-icon"></span>
        </button>

        {user &&
          <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
            <div className="navbar-nav">
              <a className="nav-item nav-link active" href="/">Start</a>
              <a className="nav-item nav-link" href="/">Ny sökning</a>

            </div>
            <div className="navbar-nav ml-auto">
              {user.username} / 
              <a className="nav-item nav-link ml-auto" href="/">Logga ut</a>
            </div>

          </div>
        }

      </div>
    </nav>

}



export default TopBar
