import React from 'react';

import {___} from "../translate"
import QRCode from 'qrcode.react';

import {generateTotp} from "../utils/auth"
import {getDeviceIdAndToken} from "../utils/device"

import AppContext from "../context"

import {MODE_KIOSK} from "../constants"

import { ReactComponent as Arrow } from '../images/arrow.svg';

const CategoryButton = ({category, selectCategory})=>{
  return <div className="category-item" onClick={()=>{selectCategory(category)}}>
    <category.icon/>
    <h3 >{___(category.name)}</h3>
  </div>
}


const GoToApp = ()=>{

  const [totp, setTotp] = React.useState("")

  const {data} = React.useContext(AppContext)
  const {deviceId, deviceToken} = getDeviceIdAndToken(data)

  const newTotp = ()=>{
    setTotp(generateTotp(deviceToken))
  }

  React.useEffect(()=>{
    const timer = setInterval(()=>{
      console.log("new totp")
      newTotp()
    }, 1000 * 15)

    newTotp()


    return ()=>{
      clearInterval(timer)
    }

  }, [])


  const url = `${process.env.REACT_APP_HANDOF_URL}/?a=${deviceId}-${totp}`
  console.log(url)
  return <div className="go-to-app">
    <h4>{___("Sök med mobilen istället")}</h4>
    <h3>{___("Scanna QR-koden")}</h3>

    <Arrow className="arrow" />
    <div className="qr-code">
    {totp &&
      <QRCode size={150} value={url} />
    }
    </div>
  </div>

}




const StartPage = ({categories, selectCategory, updateAreaSize})=>{

  const bannerEl = React.useRef()

  const {mode} = React.useContext(AppContext)

  React.useEffect(()=>{

    updateAreaSize({
      left: bannerEl.current.clientLeft,
      top: bannerEl.current.clientTop,
      width: bannerEl.current.clientWidth,
      height: bannerEl.current.clientHeight,

    })
  }, [categories])

  const enableHandoff = false

  return (
    <div className="start">

      <div className="banner" ref={bannerEl}>
        {
          <div className="tint"/>
          // <h1>Ett rent nöje!</h1>

        }
      </div>

      <div className="main">
        <h2>{___("Hitta produkter till din bil")}</h2>
        <div className="buttons">
          { categories.map((category, index)=>(
            <CategoryButton key={index} category={category} selectCategory={selectCategory}/>
          ))}

          {mode === MODE_KIOSK && enableHandoff &&
            <GoToApp/>
          }
        </div>
        <div className="copy">{___("by Smartguide")}</div>
      </div>
    </div>

  )
}

export default StartPage
